import { useEffect } from "react";
import {
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { useState } from "react";
import './spinner.css';

const style = { layout: 'horizontal', color: 'silver', shape: 'pill', height: 50, width: 10 };

const ButtonWrapper = ({ currency, showSpinner, continueshipping, checksubtotal, gettransectionpprove, disabled }) => {
    // const[paypaltransactionid,setPaypaltransactionid]=useState()
    const [paypalorderid, setPaypalorderid] = useState()
    // const[paypalstatus,setPaypalstatus]=useState()

    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
    }, [currency, showSpinner]);

    useEffect(() => {
        if (paypalorderid != null) {
            continueshipping(paypalorderid);
        }
    }, [paypalorderid])

    return (<>
        {(showSpinner && isPending) && <div className="spinner" />}
        <PayPalButtons
            style={style}
            disabled={disabled}
            forceReRender={[checksubtotal, currency, style]}
            fundingSource={undefined}
            createOrder={async (data, actions) => {
                const orderId = await actions.order
                    .create({
                        purchase_units: [
                            {
                                amount: {
                                    currency_code: currency,
                                    value: checksubtotal,
                                },
                            },
                        ],
                    });
                const orderIs = `F2${orderId}L`;
                // orderId = orderIs;
                setPaypalorderid(orderId);
                return orderId;
            }}
            onApprove={async (data, actions) => {
                const details = await actions.order.capture();
                const payments = details.purchase_units[0].payments.captures[0];
                gettransectionpprove(payments.id, details.id, payments.status,`${payments.amount.value} ${payments.amount.currency_code}`);
            }}
            onCancel={(cancel, data) => {
                console.log("Paypal cancel Is:", cancel, " data ", data)
            }}
            onError={(err) => {
                console.error("Paypal Error Is:", err)
            }}
        />
    </>
    );
}

export default ButtonWrapper; 