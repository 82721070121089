// export const apiURL = 'http://localhost:4002/'
// export const apiURL = 'http://74.208.229.140:4002/'

export const apiURL = 'https://resumeapi.bwsteam.com/'

export const reCaptchaKey = "6Lc79fAiAAAAAFss509BtRrf8uZScOofcovgyQmm"
export const paypalClientId = "AR7hqnlASqeqFHfxoNwBxefHq0cNPzfb7_ZKSNarhCulhIBA3M6_mU83C2KRQEX23IZd6ye4OqgW0RD3" 


// export const tinymceApiKey='hpv89x6nkbd7fnibw3s4ii8sm7ytlqzazuzxppga1cfzjvkj'
export const tinymceApiKey ='7os7on7fgytrx4vtoo4qiyhsn7zl9wxvbig8oixqjb1jkvzf' //new
