import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import Layout from '../../Pages/Layout';
import Table from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Paper } from '@mui/material';
import "react-data-table-component-extensions/dist/index.css";
import { useSelector, useDispatch } from 'react-redux';
import { allFaqData } from '../../../../redux/action/Action';
import { Allcustomer, StatusChange } from '../../../../redux/action/Action'
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import '../Faq/Faq.css'

const useStyle = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(20),
    width: '100%',
  },
  table: {
    margin: 'auto',
  },
  statusDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  active: {
    color: 'green',
  },
  inactive: {
    color: 'red',
  },
  ' & .active': {
    color: 'green',
  }
}))

const AllCustomer = () => {
  const active = '1';
  const inactive = '0';

  const classes = useStyle();
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [tableData, setTableData] = useState({})

  const toggleState = useSelector((state) => state.togglingReducer.togglingAll)
  const getallcustomer = useSelector((state) => state.getallcustomer.customerData)

  const ActiveCustomer = (id) => {
    let data = { "action": "CustomersStatusChange", "id": parseInt(id), "status": active }
    dispatch(StatusChange(data)).then(dispatch(Allcustomer()))
  }

  const InActiveCustomer = (id) => {
    let data = { "action": "CustomersStatusChange", "id": parseInt(id), "status": inactive }
    dispatch(StatusChange(data)).then(dispatch(Allcustomer()))
  }


  const columns = [
    {
      name: "Firstname",
      selector: row => row.cust_fname,
      sortable: true
    },
    {
      name: "Lastname",
      selector: row => row.cust_lname,
      sortable: true
    },
    {
      name: "Email",
      selector: row => row.cust_email,
      sortable: true,
    },
    {
      name: "Status",
      // selector: row => row.customer_status,
      sortable: true,
      cell: (d) => (
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={d.cust_status}
            name="radio-buttons-group"
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <FormControlLabel value={active} control={<Radio />} label="Active" onClick={() => ActiveCustomer(d.cust_id)} />
            <FormControlLabel value={inactive} control={<Radio />} label="InActive" onClick={() => InActiveCustomer(d.cust_id)} />
          </RadioGroup>
        </FormControl>
      )
    },
  ];


  useEffect(() => {
    setData(getallcustomer)
  }, [getallcustomer])

  useEffect(() => {
    dispatch(allFaqData())
  }, [])

  useEffect(() => {
    let isApiData = true;
    if (isApiData) {
      dispatch(Allcustomer());
    }
    return () => {
      isApiData = false;
    }
  }, [])

  useEffect(() => {
    setTableData((state) => {
      return {
        ...state,
        data,
        columns
      }
    })
  }, [data])

  return (
    <>
      <Layout>
        <div className={classes.root} >
          <div className={classes.student}>
            <Paper variant='outlined' className={classes.table}
              style={{ position: 'absolute', right: 0, left: toggleState ? 300 : 0, width: toggleState ? '70%' : '90%', transition: '.3s all', }}>
              <DataTableExtensions {...tableData} >
                <Table
                  columns={columns}
                  data={data}
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover

                />
              </DataTableExtensions>
            </Paper>

          </div>
        </div>
      </Layout>
    </>
  )
}

export default AllCustomer