import React from 'react';
import Footer from '../components/Footer';
import Nav from '../components/Navbar/Nav';

const Layout = ({ children }) => {

  return (
    <>
      <Nav />
      {children}
      <Footer />
    </>
  )
}

export default Layout