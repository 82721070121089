import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { AiOutlineCloseSquare } from 'react-icons/ai';
import './Cart.css';
import MiniNav from '../MiniNav/MiniNav';
import Layout from '../../Layout/Layout';
import { apiURL } from '../../../src/components/Admin/Components/Api/BaseLine';
import PackageCartpopup from '../Package/packageCartPopup';
import { cartaction, deletecartaction, updatecartaction } from '../../redux/action/Action';

const Cart = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [subtotal, setSubtotal] = useState(0);
    const [openloginpopup, setOpenloginpopup] = useState(false);
    const cartdatareducer = useSelector((state) => state.cartreducer.datacart);

    useEffect(() => {
        handleDataAndCart();
    }, []);

    useEffect(() => {
        calculateSubtotal();
    }, [cartdatareducer]);

    const handleDataAndCart = async () => {
        if (!localStorage.getItem("frontuserid")) {
            setOpenloginpopup(true);
        }

        await dispatch(cartaction(localStorage.getItem("frontuserid")));
    };

    const calculateSubtotal = () => {
        let initialsubtotal = 0;
        cartdatareducer?.forEach((item) => {
            initialsubtotal += item.cart_qty * item.cart_price;
        });
        setSubtotal(initialsubtotal);
    };

    const deletecart = async (id) => {
        Swal.fire({
            type: 'warning',
            icon: 'warning',
            text: 'Are you sure you want to delete Item?',
            showCancelButton: true,
            confirmButtonColor: '#507c37',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await dispatch(deletecartaction(id));
                await handleDataAndCart();
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved');
            }
        });
    };

    const updateQty = async (e, cart_id) => {
        const { value } = e.target;
        if (value > 0) {
            const updateData = [{ cart_id, cart_qty: value }];

            await dispatch(updatecartaction(updateData));
            await handleDataAndCart();
        }
    };

    return (
        <Layout>
            {openloginpopup && <PackageCartpopup PackagePopupclose={() => setOpenloginpopup(false)} />}
            <div className="padding_div">
                <div className="cart">
                    <MiniNav NavData={['cart', 'Cart']} />
                    <div className="container">
                        <div className="cart_content">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-xl-9">
                                    <div className="table-responsive my-table-mian-div">
                                        <table className="table" style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th className='heading-center' scope="col" style={{ width: "46%" }}>Product</th>
                                                    <th scope="col" style={{ width: "10%" }}>Price</th>
                                                    <th scope="col" style={{ width: "10%" }}>Quantity</th>
                                                    <th scope="col" style={{ width: "10%" }}>Subtotal</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {localStorage.getItem("frontuserid") != null ?
                                                    cartdatareducer?.map((item, index) => {
                                                        return (
                                                            <tr key={index} >
                                                                <td className='cart-table-td' >
                                                                    <button onClick={() => deletecart(item?.cart_id)}>
                                                                        <AiOutlineCloseSquare size={22} className="table-close-icon" />
                                                                    </button>
                                                                    <div className='cart-featured-image'>
                                                                        {item?.image ? <img src={`${apiURL}${item?.image}`} alt="book" /> : <img src='/assets/images/loginvector.png' alt='procduct' />}
                                                                    </div>
                                                                    {
                                                                        item.addons != null ?
                                                                            <div className='cart-addon-div'>
                                                                                <p>{item.product_name}</p>
                                                                                {
                                                                                    item.addons.map((v, i) => {
                                                                                        return <p key={i}>{v.addons_name} - ${v.addons_price}</p>
                                                                                    })
                                                                                }
                                                                            </div> :
                                                                            <p>{item.product_name}</p>
                                                                    }
                                                                </td>
                                                                <td >
                                                                    <p>{`$${parseFloat(item?.cart_price).toFixed(2)}`}</p>
                                                                </td>

                                                                <td className="cart-table-input">
                                                                    <input
                                                                        type={item.addons && item.addons.length > 0 ? "text" : "number"}
                                                                        id={item.cart_id}
                                                                        name="quantity"
                                                                        min="1"
                                                                        max={item.addons && item.addons.length > 0 ? "1" : undefined}
                                                                        value={item.cart_qty}
                                                                        disabled={item.addons && item.addons.length > 0}
                                                                        onChange={item.addons && item.addons.length > 0 ? undefined : (e) => updateQty(e, item?.cart_id)}
                                                                    />
                                                                </td>

                                                                <td>
                                                                    <p>{`$${parseFloat(item?.cart_price * item?.cart_qty).toFixed(2)}`}</p>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : null

                                                }
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-3 cart_checkout">
                                    <div className="cart_heading text-center ">
                                        <h4 className='pb-3 pt-3'>CART TOTALS</h4>
                                    </div>
                                    <div className="cart_amout">
                                        <div className="subtotal">
                                        </div>
                                        <div className="subtotal ">
                                            <div className="row">
                                                <h3 className='col-md-6 col-lg-6'>Total</h3>
                                                <h5 className='col-md-6 col-lg-6 final_total'>{`$${parseFloat(subtotal).toFixed(2)}`}</h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 col-lg-12 cart_btn mb-3">
                                            <button onClick={() => navigate('/checkout')} className="btn w-100 auth_btn" disabled={cartdatareducer.length < 1}>
                                                Proceed to Checkout
                                            </button>
                                        </div>
                                    </div>
                                    <div className="cart_check_links d-flex ">
                                        <div className="row justify-content-center">
                                            <div className="col-md-3 col-lg-3  col-sm-3 col-3 me-2 shop">
                                                <img src="./assets/images/shoppay_white.svg" alt="shoppay" />
                                            </div>
                                            <div className="col-md-3 col-lg-3  col-sm-3 col-3 me-2 paypal">
                                                <img src="./assets/images/paypal.svg" alt="paypal" />
                                            </div>
                                            <div className="col-md-3 col-lg-3  col-sm-3 col-3 me-2 gpay">
                                                <img src="./assets/images/google_pay.svg" alt="gpay" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Cart;