export const steps = [
    {
        id: 1,
        name: 'Name'
    },
    {
        id: 2,
        name: 'Address'
    },
    {
        id: 3,
        name: 'Phone'
    },
    {
        id: 4,
        name: 'Lindedin'
    },
    {
        id: 5,
        name: 'Education'
    },
    {
        id: 6,
        name: 'Employer'
    },
    {
        id: 7,
        name: 'Technical Skills'
    },
    {
        id: 8,
        name: 'Other information'
    },
]