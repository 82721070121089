export const TOGGLE_ALL =  'TOGGLE_ALL'

// for categories types
export const ALLCATEGORIES    =  'ALLCATEGORIES'
export const ADDCATEGORIES    =  'ADDCATEGORIES'
export const DELETECATEGORIES =  'DELETECATEGORIES'
export const EDITCATEGORIES   =  'EDITCATEGORIES'
export const EDITFIELD        =  'EDITFIELD'
export const ADDFIELD         =  'ADDFIELD'
export const ALLFIELDS        =  'ALLFIELDS'
export const DELETEFIELD      =  'DELETEFIELD'
export const STATUSCHANGE     =  'STATUSCHANGE'
export const ADDCUSTOMERS     =  'ADDCUSTOMERS'
export const PACKAGEADDON     =  'PACKAGEADDON'
