import React, { useEffect } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import editorConfiguration from '../../utils/editorConfiguration';



export const CompanyForm = ({ allValues, isUpdating = false, updatingCompanies, animation }) => {

    const initialValues = {
        friends: isUpdating ? updatingCompanies : [{
            company_name: '',
            company_address: '',
            date_of_form: '',
            ToEmployment: '',
            job_title: '',
            direct_report: '',
            editorAchievements: ''
        }]
    };

    return (
        <div>
            <Formik
                initialValues={initialValues}
            >
                {({ values, handleChange, handleBlur, setFieldValue }) => {
                    return (
                        <>
                            <ValuesUpdater values={values} allValues={allValues} />
                            <Form>
                                <FieldArray
                                    name="friends"
                                    render={arrayHelpers => (
                                        <div>
                                            {values.friends && values.friends.length > 0 ? (
                                                values.friends.map((friend, index) => (
                                                    <div key={index}>
                                                        <div className='but_position'>
                                                            <button
                                                                className='comp_step subtract'
                                                                type="button"
                                                                onClick={() => arrayHelpers.remove(index)}
                                                            >
                                                                -
                                                            </button>

                                                            <button
                                                                className='comp_step'
                                                                type="button"
                                                                onClick={() => arrayHelpers.insert(index, {
                                                                    company_name: '',
                                                                    company_address: '',
                                                                    date_of_form: '',
                                                                    ToEmployment: '',
                                                                    job_title: '',
                                                                    direct_report: '',
                                                                    editorAchievements: ''
                                                                })}
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                        <div className={animation ? "row animated bounceInRight" : "row animated bounceInLeft"}>
                                                            <div className="mb-4 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <label htmlFor={`friends[${index}].company_name`} className="form-label">Company Name</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id={`friends[${index}].company_name`}
                                                                    placeholder="Company Name"
                                                                    name={`friends[${index}].company_name`}
                                                                    value={friend.company_name}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>

                                                            <div className="mb-4 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <label htmlFor={`friends[${index}].company_address`} className="form-label">Company's Address</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id={`friends[${index}].company_address`}
                                                                    placeholder="Company's Address"
                                                                    name={`friends[${index}].company_address`}
                                                                    value={friend.company_address}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>

                                                            <div className="mb-4 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <label htmlFor={`friends[${index}].date_of_form`} className="form-label">Dates of Employment: From</label>
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    id={`friends[${index}].date_of_form`}
                                                                    placeholder="Dates of Employment"
                                                                    name={`friends[${index}].date_of_form`}
                                                                    value={friend.date_of_form}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>

                                                            <div className="mb-4 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <label htmlFor={`friends[${index}].ToEmployment`} className="form-label">To</label>
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    id={`friends[${index}].ToEmployment`}
                                                                    placeholder="To Employment"
                                                                    name={`friends[${index}].ToEmployment`}
                                                                    value={friend.ToEmployment}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>

                                                            <div className="mb-4 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <label htmlFor={`friends[${index}].job_title`} className="form-label">Job title</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id={`friends[${index}].job_title`}
                                                                    placeholder="Job title"
                                                                    name={`friends[${index}].job_title`}
                                                                    value={friend.job_title}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>

                                                            <div className="mb-4 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <label htmlFor={`friends[${index}].direct_report`} className="form-label">Direct Report</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id={`friends[${index}].direct_report`}
                                                                    placeholder="direct_report"
                                                                    name={`friends[${index}].direct_report`}
                                                                    value={friend.direct_report}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>

                                                            <div className="mb-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                <label htmlFor={`friends[${index}].editorAchievements`} className="form-label">Description of duties and achievements</label>
                                                                <div className='achievements'>
                                                                    <CKEditor
                                                                        editor={ClassicEditor}
                                                                        config={editorConfiguration}
                                                                        data={friend.editorAchievements}
                                                                        onChange={(event, editor) => {
                                                                            const data = editor.getData();
                                                                            setFieldValue(`friends[${index}].editorAchievements`, data);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <button type="button" onClick={() => arrayHelpers.push({ company_name: '' })}>
                                                    Add a friend
                                                </button>
                                            )}
                                        </div>
                                    )}
                                />
                            </Form>
                        </>
                    )
                }}
            </Formik>
        </div>
    );
};

// New component to handle useEffect for values update
const ValuesUpdater = ({ values, allValues, isUpdating, updatingCompanies }) => {
    useEffect(() => {
        allValues(values);
    }, [values, allValues]);

    return null;
};
