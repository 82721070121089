import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import Layout from '../../Pages/Layout';
import Table from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Paper, Box, Button } from '@mui/material';
import "react-data-table-component-extensions/dist/index.css";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { allFaqData } from '../../../../redux/action/Action';
import { deleteFaq } from '../../../../redux/action/Action'
import { getIdToEditFAQ } from '../../../../redux/action/Action'
import Swal from "sweetalert2";
import './Faq.css'
import { MdEdit, MdDelete } from "react-icons/md";

const MyRoot = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(20),
  width: '100%',
}));
const DefaultButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#d0989b',
  '&:hover': {
    backgroundColor: '#d0989b',
  },
}));

const FaqTable = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch()

  const [data, setData] = useState([])
  const [tableData, setTableData] = useState({})
  const toggleState = useSelector((state) => state.togglingReducer.togglingAll)
  const getFaqAllData = useSelector((state) => state.GetFaqDataReducer.FaqAllData)

  if (getFaqAllData === undefined) {
    dispatch(allFaqData())
  }

  const handleEdit = (id) => {
    dispatch(getIdToEditFAQ(id))
      .then(() => Navigate(`/admin/editfaq/${id}`))
  };

  const handleDelete = (id) => {
    Swal.fire({
      type: 'warning',
      icon: 'warning',
      text: 'Are you sure you want to delete Faq?',
      showCancelButton: true,
      confirmButtonColor: '#507c37',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteFaq(id)).then(() => dispatch(allFaqData()))
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved')
      }
    })
  }

  const columns = [
    {
      name: 'S.No',
      selector: row => row['.s.no'],
      sortable: true,
      width: '10%',
      cell: (row, index) => index + 1
    }
    , {
      name: "Questions",
      selector: row => row.faq_title,
      sortable: true,
      width: '70%',
      headerStyle: (selector, id) => {
        return { textAlign: "center" }
      },
    },
    {
      name: "status",
      selector: row => row.faq_status,
      sortable: true,
      width: '10%',
      headerStyle: (selector, id) => {
        return { textAlign: "center" }
      },
      cell: ((row) => {
        if (row.faq_status === "0") {
          return 'inactive'
        } else if (row.faq_status === "1") {
          return 'active'
        } else {
          return row.faq_status
        }
      })
    },
    {
      name: "Action",
      selector: row => row.id,
      sortable: false,
      width: '10%',
      headerStyle: (selector, id) => {
        return { textAlign: "center" }
      },
      cell: (d) => [
        <MdEdit size={24} key='1' color={'#44449b'} onClick={() => handleEdit(d.faq_id)} style={{ cursor: 'pointer' }} />,
        <MdDelete size={24} key='2' color={'#e13d3d'} onClick={() => handleDelete(d.faq_id)} style={{ cursor: 'pointer' }} />,
      ],
    }
  ];

  useEffect(() => {
    setData(getFaqAllData)
  }, [getFaqAllData])

  useEffect(() => {
    dispatch(allFaqData())
  }, [])

  useEffect(() => {
    setTableData((state) => {
      return {
        ...state,
        data,
        columns
      }
    })
  }, [data])

  return (
    <>
      <Layout>
        <MyRoot>
          <div style={{
            position: 'absolute', right: 0, left: toggleState ? 300 : 0,
            width: toggleState ? '70%' : '90%', transition: '.3s all', margin: 'auto'
          }}>
            <Box style={{ textAlign: 'right', marginBottom: '20px' }}>
              <DefaultButton
                variant="contained"
                onClick={() => Navigate('/admin/addfaq')}
              >
                Add FAQ
              </DefaultButton>
            </Box>
            <Paper variant='outlined' style={{ margin: 'auto' }}>
              <DataTableExtensions {...tableData} >
                <Table
                  columns={columns}
                  data={data}
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                />
              </DataTableExtensions>
            </Paper>
          </div>
        </MyRoot>
      </Layout>
    </>
  )
}

export default FaqTable