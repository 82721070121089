import React, { useEffect } from 'react';
import { Formik, Form, FieldArray } from 'formik';

export const EducationForm = ({ instituteValue, isUpdating = false, updatingInstitutes, animation }) => {

    const initialValues = {
        friend: isUpdating ? updatingInstitutes : [{
            institude: '',
            location: '',
            degree: '',
            concentration: '',
            startDate: '',
            toEnd: ''
        }]
    };

    return (
        <div>
            <Formik
                initialValues={initialValues}
            >
                {({ values, handleChange, handleBlur }) => {
                    return (
                        <>
                            <ValuesUpdater values={values} instituteValue={instituteValue} />
                            <Form>
                                <FieldArray
                                    name="friend"
                                    render={arrayHelpers => (
                                        <div>
                                            {values.friend && values.friend.length > 0 ? (
                                                values.friend.map((institute, index) => (
                                                    <div key={index}>
                                                        <div className='but_position'>
                                                            <button
                                                                className='comp_step subtract'
                                                                type="button"
                                                                onClick={() => arrayHelpers.remove(index)}
                                                            >
                                                                -
                                                            </button>

                                                            <button
                                                                className='comp_step'
                                                                type="button"
                                                                onClick={() => {
                                                                    if (values.friend.length < 3) {
                                                                        arrayHelpers.insert(index, {
                                                                            institude: '',
                                                                            location: '',
                                                                            degree: '',
                                                                            concentration: '',
                                                                            startDate: '',
                                                                            toEnd: ''
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                        <div className={animation ? "row animated bounceInRight" : "row animated bounceInLeft"}>
                                                            <div className="mb-4 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <label htmlFor={`friend[${index}].institude`} className="form-label">Institution Name</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id={`friend[${index}].institude`}
                                                                    placeholder="Institution Name"
                                                                    name={`friend[${index}].institude`}
                                                                    value={institute.institude}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>

                                                            <div className="mb-4 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <label htmlFor={`friend[${index}].location`} className="form-label">Location</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id={`friend[${index}].location`}
                                                                    placeholder="Location"
                                                                    name={`friend[${index}].location`}
                                                                    value={institute.location}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>

                                                            <div className="mb-4 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <label htmlFor={`friend[${index}].degree`} className="form-label">Degree</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id={`friend[${index}].degree`}
                                                                    placeholder="Degree"
                                                                    name={`friend[${index}].degree`}
                                                                    value={institute.degree}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>

                                                            <div className="mb-4 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <label htmlFor={`friend[${index}].concentration`} className="form-label">Concentration</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id={`friend[${index}].concentration`}
                                                                    placeholder="Concentration"
                                                                    name={`friend[${index}].concentration`}
                                                                    value={institute.concentration}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>

                                                            <div className="mb-4 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <label htmlFor={`friend[${index}].startDate`} className="form-label">Start Date</label>
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    id={`friend[${index}].startDate`}
                                                                    name={`friend[${index}].startDate`}
                                                                    value={institute.startDate}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>

                                                            <div className="mb-4 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <label htmlFor={`friend[${index}].toEnd`} className="form-label">To</label>
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    id={`friend[${index}].toEnd`}
                                                                    name={`friend[${index}].toEnd`}
                                                                    value={institute.toEnd}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <button type="button" onClick={() => arrayHelpers.push({
                                                    institude: '',
                                                    location: '',
                                                    degree: '',
                                                    concentration: '',
                                                    startDate: '',
                                                    toEnd: ''
                                                })}>
                                                    Add an institute
                                                </button>
                                            )}
                                        </div>
                                    )}
                                />
                            </Form>
                        </>
                    )
                }}
            </Formik>
        </div>
    );
};

// New component to handle useEffect for values update
const ValuesUpdater = ({ values, instituteValue, isUpdating, updatingCompanies }) => {
    useEffect(() => {
        instituteValue(values);
    }, [values, instituteValue]);

    return null;
};
